import { Box, Card, Autocomplete, TextField, Button, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import PsychologyIcon from '@mui/icons-material/Psychology';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import React, { useState, useEffect } from 'react';
var fighterData = require('./fighters.json');

function PredictionCard() {
    const [open, setOpen] = useState(false);
    const [fighterOne, setFighterOne] = useState(null);
    const [fighterTwo, setFighterTwo] = useState(null);
    const [predictedWinner, setPredictedWinner] = useState('');
    const [predictedWinnerConfidence, setPredictedWinnerConfidence] = useState('');
    const [fighters, setFighters] = useState([]);

    useEffect(() => {
        let fighters = [];
        fighterData.forEach((fighter) => {
            fighters.push({ label: fighter.fighter });
        });
        setFighters(fighters);
    }, []);

    const handleClickOpen = async () => {
        if (fighterOne && fighterTwo) {
            const response = await fetch(`http://18.216.118.198:5000/predict_winner/${fighterOne}/${fighterTwo}`);
            const result = await response.json();
            console.log(result.winner);
            setPredictedWinner(result.winner);
            setPredictedWinnerConfidence(result.confidence);
            setOpen(true);
        } else {
            alert('Please select both fighters.');
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Card sx={{ minWidth: 275, borderTop: '4px solid rgb(189, 38, 40)' }}>
                <Box sx={{ display: 'flex', flexDirection: window.innerWidth < 600 ? 'column' : 'row', padding: '25px', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <Autocomplete
                        disablePortal
                        id="fighter-one-selection"
                        options={fighters}
                        sx={{ width: 300, marginRight: window.innerWidth < 600 ? '0px' : '10px' }}
                        onChange={(event, newValue) => setFighterOne(newValue ? newValue.label : null)}
                        renderInput={(params) => <TextField {...params} label="Fighter One" />}
                    />
                    <SportsKabaddiIcon style={{ marginTop: window.innerWidth < 600 ? '10px' : '0px', marginBottom: window.innerWidth < 600 ? '10px' : '0px' }} />
                    <Autocomplete
                        disablePortal
                        id="fighter-two-selection"
                        options={fighters}
                        sx={{ width: 300, marginLeft: window.innerWidth < 600 ? '0px' : '10px' }}
                        onChange={(event, newValue) => setFighterTwo(newValue ? newValue.label : null)}
                        renderInput={(params) => <TextField {...params} label="Fighter Two" />}
                    />
                    <Tooltip title="Predict Fight" arrow>
                        <Button
                            variant="contained"
                            sx={{ marginLeft: window.innerWidth < 600 ? '0px' : '5px', height: '55px', width: window.innerWidth < 600 ? '300px' : '0px', marginTop: window.innerWidth < 600 ? '10px' : '0px', backgroundColor: 'rgb(189, 38, 40)' }}
                            title="Predict Fight"
                            onClick={handleClickOpen}
                        >
                            <PsychologyIcon />
                        </Button>
                    </Tooltip>
                </Box>
            </Card>
            <Dialog open={open} onClose={handleClose} style={{padding:'10px'}}>
                <DialogTitle style={{fontWeight:'bold'}}>Prediction Result</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'row'}} >
                        <EmojiEventsIcon/>
                        <DialogContentText style={{marginLeft:'10px'}}>
                            {predictedWinner ? predictedWinner : 'Loading...'}
                        </DialogContentText>
                    </Box>
                    <p></p>
                    <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                        <CrisisAlertIcon/>
                        <DialogContentText style={{marginLeft:'10px'}}>
                            {predictedWinnerConfidence ? + predictedWinnerConfidence + '%' : ''}
                        </DialogContentText>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default PredictionCard;
