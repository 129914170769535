import {Box, Card, CardContent} from '@mui/material';
import React, { useState, useEffect } from 'react';
import SportsMmaOutlinedIcon from '@mui/icons-material/SportsMmaOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

function NextFightCard() {

    const [upcomingFights, setUpcomingFights] = useState([]);

    useEffect(() => {
        const response = fetch(`http://18.216.118.198:5000/get_next_fights`);
        response.then((res) => res.json()).then((data) => {
            setUpcomingFights(data.upcoming_fights);
        });
    }, []);

    return(
        <div>
        <Card sx={{ minWidth: 275, borderTop: '4px solid rgb(189, 38, 40)', marginBottom: '50px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', padding: '25px' }}>
                {upcomingFights.map((fight, index) => (
                    <Card key={index} sx={{ margin: '10px', width: '90%'}}>
                        <CardContent>
                            <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                                <SportsMmaOutlinedIcon sx={{marginTop:'1rem'}} />
                                <h4 style={{marginLeft:'0.2rem'}}>{fight.fight_title}</h4>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                                <EventOutlinedIcon sx={{marginTop:'0.7rem'}} />
                                <p style={{marginLeft:'0.2rem'}}>{fight.fight_date}</p>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                                <LocationOnOutlinedIcon sx={{marginTop:'0.7rem'}} />
                                <p style={{marginLeft:'0.2rem'}}>{fight.fight_location}</p>
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        </Card>
        </div>
    )
}

export default NextFightCard;
