import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box, Menu, MenuItem, Autocomplete, TextField, Alert } from '@mui/material';
import PredictionCard from './PredictionCard';
import NextFightCard  from './NextFightCard';

function App() {

  return (
    <div>
      <AppBar position="static" style={{ backgroundColor: 'rgb(189, 38, 40)' }}>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px'}}>
            <img src="/icons8-boxing-64.png" alt="boxing" style={{ marginRight: '10px', width:'50px', height:'50px'}} />
            <Typography variant="h6" style={{ flexGrow: 1, fontWeight: 'bold' }}>
              MMA Math  
              <sub style={{ fontSize: '0.5em' }}> Powered by AI</sub>
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Container sx={{marginTop: '25px'}}>
        <Alert severity="warning">
          <strong>Disclaimer:</strong> This is an experimental project and the predictions are not guaranteed to be accurate. Prediction results should not be used for betting or gambling. Predictions are made using fighter data and statistics which may not be up to date. Predictions are made using a Random Forest Classifier model trained on collected fight data. The model used takes into account various features such as fighter age, height, reach, win/loss ratio, and other fighter statistics. The model is not perfect and may not always predict the correct winner.
        </Alert>
        <p></p>
        <p></p>
        <PredictionCard />
        <p></p>
        <p></p>
        <NextFightCard />
      </Container>
    </div>
  );
}

export default App;